$primary: #ff665b;
$secondary: #172838;

$body-bg: $secondary;
$body-color: #ffffff;

$badge-font-size: 0.8em;
$badge-padding-y: 0.55em;
$badge-padding-x: 0.85em;

$font-family-base: 'Montserrat';

html {
  box-sizing: border-box;
}

ul {
  list-style: none;
}
.nav {
  width: 100%;
  height: 75px;
}

.nav-container {
  padding: 2% 0;
}

.nav-container ul {
  flex: 1 0 auto;
  gap: 10%;
}

.hero-bg {
  background: url('../Assets/starrynight.jpg') no-repeat center center;
}
.main-container-hero {
  height: 600px;
  width: 100%;
  gap: 5%;
  padding: 0 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.flying-coder-container svg {
  width: 80%;
  height: 80%;
}
.main-container-hero h1 {
  z-index: 2;
}

.fancy-hover,
.headline-underline,
.category-underline {
  text-decoration: none;
  position: relative;
  z-index: 1;
}

.category-underline::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  height: 7px;
  width: 100%;
  border: solid 3px #ff665b;
  border-color: #ff665b transparent transparent transparent;
  border-radius: 50%;
}

.fancy-hover::before {
  content: '';
  background-color: #ff665b;
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 2px;
  z-index: -1;
  transition: all 0.2s ease-in-out;
}

.headline-underline::before {
  content: '';
  background-color: #ff665b;
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 30%;
  z-index: -1;
  transition: all 0.2s ease-in-out;
  border-radius: 2px;
}

.fancy-hover:hover::before,
.headline-underline:hover::before {
  bottom: 0;
  height: 100%;
}

.grow {
  transition: all 0.3s ease-in-out;
}
.grow:hover {
  transform: scale(1.1);
}

.aboutme-main {
  width: 100%;
  min-height: 600px;
  padding: 2% 15%;
}

.projects-main {
  width: 100%;
  min-height: 700px;
  padding: 0.5% 15%;
}

.contact-main {
  width: 100%;
  min-height: 250px;
  padding-top: 100px;
}

.contact-container-bg {
  width: 100%;
  background-color: $secondary;
  min-height: 180px;
  position: absolute;
  margin-top: 100px;
}

.contact-container {
  background-color: #f5f6fa;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 5px 5px 0 rgb(0 0 0 / 20%), 0 0 0 1px #05070c;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  width: 55%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.email {
  cursor: pointer;
}

.footer-icons {
  padding: 40px 0 0 50px;
}

.contact-container h3 {
  font-weight: 700;
}
.dotted-underline {
  border-bottom: 3px dotted #ff665b;
  cursor: pointer;
}

@media all and (max-width: 1000px) {
  .main-container-hero,
  .aboutme-main,
  .projects-main,
  .contact-main {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    gap: 0;
  }

  .projects-main {
    padding-top: 10%;
  }

  .contact-main {
    padding-top: 150px;
  }
  .projects-main div {
    padding: 0 3%;
  }

  .contact-container {
    width: 95%;
    margin-bottom: 0px;
    bottom: 65px;
    flex-direction: column;
  }

  .card-body {
    margin: 20px;
  }
  .flying-coder-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    margin: 0;
  }

  .aboutme-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .aboutme-main div img {
    padding-bottom: 50px;
    margin: 0 auto;
  }

  .second-line span {
    padding-top: 20px;
  }

  .project-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  .footer-icons {
    margin: 0;
    padding-right: 5%;
    padding-left: 5%;
  }
}
// Style at bottom
@import 'node_modules/bootstrap/scss/bootstrap.scss';
